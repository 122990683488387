import React from 'react';

import { Auth } from 'aws-amplify';

export default function Login() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
          <img alt="icon" src={process.env.PUBLIC_URL + "/icon.png"}/>
        </div>
        <div className="App-title">
          launchpad
        </div>
      </header>
      <div className="App-login-container">
        <div className="App-login-body">
          <h1>Welcome to VidMob Launchpad</h1>
          <p>
            This is your dashboard to share and explore VidMob's internal data, 
            brought to you by the VidMob Product team.
          </p>
          <h2>Get started</h2>
          <p className="App-subheader">
            How do I use the Launchpad?
          </p>
          <p>
            Log in to access a menu of reports, organized into categories. Select the report 
            you'd like to review or download. 
          </p>
          <p>
            Each report is broken down into a number of sections, including filters to apply 
            and charts. Apply filters, maximize, or export each section to better view or 
            interpret the data.
          </p>
          <p className="App-subheader">
            Can anyone use the Launchpad?
          </p>
          <p>
            Any VidMob employee can access and add reports. 
          </p>
          <p className="App-subheader">
            Can I add more reports and data to the Launchpad?
          </p>
          <p>
            Yes, if you have a report you'd like to share with others, please reach out 
            to <a href="mailto:parth.shah@vidmob.com">Parth</a>. 
          </p>
          <p className="App-subheader">
            I have more questions and suggestions
          </p>
          <p>
            Great! Get in touch with <a href="mailto:parth.shah@vidmob.com">Parth</a>. 
          </p>
          <p className="App-subheader">
            Ready for launch?
          </p>
          <div className="App-signin">
            <button onClick={() => Auth.federatedSignIn({ provider: "Google"} )}/>
          </div>
        </div>
      </div>
    </div>
  );
}