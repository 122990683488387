import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { API, graphqlOperation } from 'aws-amplify';

import { createPad } from '../graphql/mutations';

import './AdminAddPad.css';

const CATEGORIES = [
  'Product Support',
  'Product',
  'Client Services',
  'Sales',
  'Marketing',
  'Finance',
];

// save pad to graphql API
async function savePad(input) {
  console.log('savePad input: ', input);
  const result = await API.graphql(graphqlOperation(createPad, {input}));
  console.log('savePad result: ', result);
  return result;
}

export default function AdminAddPad() {
  // bind form values to state
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(CATEGORIES[0]);
  const [dashboardId, setDashboardId] = useState('');
  const [author, setAuthor] = useState('');
  const [owner, setOwner] = useState('');
  const [persona, setPersona] = useState('N/A');

  const navigate = useNavigate();
  
  function handleFormSubmit(event) {
    event.preventDefault();

    if (title === '' || description === '' || dashboardId === '' || author === '' || owner === '' || persona === '') {
      alert('Please fill out all fields');
      return;
    }
    
    if (dashboardId.length !== 36) {
      alert('Dashboard ID must be 36 characters');
      return;
    }

    // match dashboardId to regex for UUID
    const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');
    if (!uuidRegex.test(dashboardId)) {
      alert('Dashboard ID must be a valid UUID');
      return;
    }

    const input = {
      title,
      description,
      category,
      entity: dashboardId,
      source: 'QUICKSIGHT',
      author: {
        name: author,
      },
      owner: {
        name: owner,
      },
      persona,
    };
    console.log('Form submitted with input: ', input);

    savePad(input).then(() => {
      alert('Pad saved!');
      setTitle('');
      setDescription('');
      setCategory(CATEGORIES[0]);
      setDashboardId('');
      setAuthor('');
      setOwner('');
      setPersona('');

      // navigate to /
      navigate('/');
    }).catch((error) => {
      alert('Error saving pad. Please try again.');
      console.log('Error saving pad: ', error);
    });
  }

  return (
    <div className="AdminAddPad">
      {/* Add form with title, description, category (Product, Engineering), dashboard id, author title, owner title, and persona */}
      <form className="AdminAddPad-form" onSubmit={handleFormSubmit}>
        <div className="AdminAddPad-item">
          <label htmlFor="title">Title</label>
          <input type="text" id="title" title="title" onChange={(event) => setTitle(event.target.value)}/>
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="description">Description</label>
          <textarea id="description" title="description" onChange={(event) => setDescription(event.target.value)}></textarea>
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="category">Category</label>
          <select id="category" title="category" onChange={(event) => setCategory(event.target.value)}>
            {/* map over CATEGORIES and create an option for each */}
            { CATEGORIES.map(category => <option key={category} value={category}>{category}</option>) }
          </select>
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="dashboardId">Dashboard ID</label>
          <input type="text" id="dashboardId" title="dashboardId" onChange={(event) => setDashboardId(event.target.value)} />
          <div className="AdminAddPad-item-info">
            From Quicksight URL (ex: 2ae547a1-77ea-4c51-91e6-d8684f9f42d3)
          </div>
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="author">Author</label>
          <input type="text" id="author" title="author" onChange={(event) => setAuthor(event.target.value)} />
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="owner">Owner</label>
          <input type="text" id="owner" title="owner" onChange={(event) => setOwner(event.target.value)} />
        </div>
        <div className="AdminAddPad-item">
          <label htmlFor="persona">Persona</label>
          <input type="text" id="persona" title="persona" onChange={(event) => setPersona(event.target.value)} />
        </div>
        <div className="AdminAddPad-item">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}
