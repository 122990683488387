import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export default function Header(props) {
  return (
    <header className="App-header">
      <div className="App-logo">
        <Link to="/">
          <img alt="icon" src={process.env.PUBLIC_URL + "/icon.png"}/>
        </Link>
      </div>
      <div className="App-title">
        launchpad
      </div>
      { !props.isAdminUser ||
        (
          <div className="App-add-pad">
            <Link to="/admin/add-pad">
              <button>add pad</button>
            </Link>
          </div>
        )
      }
      <div className="App-signout">
        <button
          onClick={() => Auth.signOut()}
        >log out</button>
      </div>
    </header>
  );
}