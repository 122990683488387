import React, {useState, useEffect} from 'react';

import { Link, useParams } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { getQuicksightEmbedUrl } from '../graphql/queries';

export default function Pad() {
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const callListPads = async () => {
      const variables = { padId: id };
      const result = await API.graphql(graphqlOperation(getQuicksightEmbedUrl, variables));
      console.log("GOT RESULT");
      console.log(result);

      const url = result?.data?.getQuicksightEmbedUrl?.iframeUrl;

      setUrl(url);
      setIsLoading(false);
    }
    callListPads();
  }, [id]);

  if (isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <div className="Pad">
      <div className="Pad-back">
        <Link to="/">
          <button className="navButton">〈 Go back</button>
        </Link>
      </div>
      <div className="Pad-iframe">
        <iframe className="embed-iframe" title="Quicksight Dashboard" src={url} />
      </div>
    </div>
  );
}
