import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import Amplify from 'aws-amplify';
import config from './aws-exports';

const { NODE_ENV } = process.env;
const LOCAL_URL = 'http://localhost:3000';

// config.oauth.redirectSignIn = LOCAL_URL;
// config.oauth.redirectSignOut = LOCAL_URL;
// console.log(config);

Amplify.configure(config);

mixpanel.init('4f473376bf5d75cf1fabf46cdc07ec82', {debug: true, ignore_dnt: true}); 
mixpanel.track('Page load');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
