import './App.css';

import React, {useState, useEffect} from 'react';

import { Routes, Route } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

import Header from './components/header';
import Pad from './views/Pad';
import List from './views/List';
import NotFound from './views/NotFound';
import AdminAddPad from './views/AdminAddPad';
import Login from './views/Login';

function getGroups(user) {
  if (user) {
    return user.signInUserSession?.accessToken?.payload['cognito:groups'];
  }
}

function isAdmin(user) {
  const groups = getGroups(user);
  return groups?.includes('admin') ?? false;
}

export default function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);

  useEffect(() => {
    checkLogin();
  }, []);

  function checkLogin() {
    Auth.currentAuthenticatedUser().then(user => {
      console.log('user: ', user);
      if (user) {
        setLoggedIn(true);
        const email = user.attributes?.email;
        if (email) {
          mixpanel.identify(email);
          mixpanel.register({email});

          if (window.aptrinsic) {
            window.aptrinsic('identify', {id: user.username, email});  
          } else {
            console.warn('aptrinsic is not loaded');
          }
        }

        mixpanel.track('Logged in');
      }
      
      setLoading(false);
      setIsAdminUser(isAdmin(user));
    }).catch(e => {
      if (e !== "The user is not authenticated") {
        console.debug(`Failed on check user: ${JSON.stringify(e)}`);
      }
      setLoggedIn(false);
      setLoading(false);
    });
  }
  
  if (loading) {
    return (
      <div className="App">
        <header className="App-header">
          Loading...
        </header>
      </div>
    );
  }

  if (!loggedIn) {
    return (<Login />);
  }

  return (
    <div className="App">
      <Header isAdminUser={isAdminUser}/>
      <div className="App-body">
        <Routes>
          <Route exact path="/" element={<List/>} />
          <Route path="/pad/:id" element={<Pad/>} />
          { !isAdminUser || (<Route path="/admin/add-pad" element={<AdminAddPad/>} />) }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}
