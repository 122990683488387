/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPad = /* GraphQL */ `
  query GetPad($id: ID!) {
    getPad(id: $id) {
      id
      title
      description
      category
      author {
        name
        photo
      }
      owner {
        name
        photo
      }
      persona
      source
      entity
      order
      createdAt
      updatedAt
    }
  }
`;
export const listPads = /* GraphQL */ `
  query ListPads(
    $id: ID
    $filter: ModelPadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPads(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        category
        author {
          name
          photo
        }
        owner {
          name
          photo
        }
        persona
        source
        entity
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuicksightEmbedUrl = /* GraphQL */ `
  query GetQuicksightEmbedUrl($padId: ID) {
    getQuicksightEmbedUrl(padId: $padId) {
      padId
      iframeUrl
    }
  }
`;
