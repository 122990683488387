/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPad = /* GraphQL */ `
  mutation CreatePad(
    $input: CreatePadInput!
    $condition: ModelPadConditionInput
  ) {
    createPad(input: $input, condition: $condition) {
      id
      title
      description
      category
      author {
        name
        photo
      }
      owner {
        name
        photo
      }
      persona
      source
      entity
      order
      createdAt
      updatedAt
    }
  }
`;
export const updatePad = /* GraphQL */ `
  mutation UpdatePad(
    $input: UpdatePadInput!
    $condition: ModelPadConditionInput
  ) {
    updatePad(input: $input, condition: $condition) {
      id
      title
      description
      category
      author {
        name
        photo
      }
      owner {
        name
        photo
      }
      persona
      source
      entity
      order
      createdAt
      updatedAt
    }
  }
`;
export const deletePad = /* GraphQL */ `
  mutation DeletePad(
    $input: DeletePadInput!
    $condition: ModelPadConditionInput
  ) {
    deletePad(input: $input, condition: $condition) {
      id
      title
      description
      category
      author {
        name
        photo
      }
      owner {
        name
        photo
      }
      persona
      source
      entity
      order
      createdAt
      updatedAt
    }
  }
`;
