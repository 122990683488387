import React from 'react';

import './NotFound.css';

export default function NotFound() {
  return (
    <div className="NotFound-title">
      Not Found
    </div>
  );
}