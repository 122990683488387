import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import mixpanel from 'mixpanel-browser';

import { API, graphqlOperation } from 'aws-amplify';
import { listPads } from '../graphql/queries';
import './List.css';

function getUrlIconUrl(url) {
  const u = new URL(url);
  console.log(u);
  return `${u.origin}/favicon.ico`;
}

function getUrlIconFallback(url) {
  const u = new URL(url);
  console.log(u);
  return `https://s2.googleusercontent.com/s2/favicons?domain=${u.host}`;
}

function getSourceIconUrl(pad) {
  if (pad.source === 'QUICKSIGHT') {
    return process.env.PUBLIC_URL + "/amazon-quicksight.svg";
  } else if (pad.entity.startsWith('https://')) {
    return getUrlIconUrl(pad.entity);
  }
}

function ListItem(props) {
  const navigate = useNavigate();

  const sourceIconUrl = getSourceIconUrl(props.pad);

  const openReport = () => {
    mixpanel.track("Open report", {title: props.pad.title});

    if (props.pad.source === 'QUICKSIGHT') {
      navigate(`/pad/${props.pad.id}`)
    } else if (props.pad.entity.startsWith('https://')) {
      window.open(props.pad.entity, '_blank', 'noopener,noreferrer');
    }
  }

  return (
    <div className="ListItem" onClick={() => openReport()}>
      <div className="ListCol1">
        <div className="ListTitle">{props.pad.title}</div>
        <div className="ListDescription">{props.pad.description}</div>
      </div>
      <div className="ListCol2">
        {props.pad.author && (
          <div className="ListItemProfile">
            <div className="ListItemProfilePhoto">
              <img alt={props.pad.author.name} src={process.env.PUBLIC_URL + "/people-default.svg"}/>
            </div>
            <div className="ListItemProfileName">
              {props.pad.author.name}
            </div>
          </div>
        )}
        <div className="ListItemSubtitle">Author</div>
      </div>
      <div className="ListCol3">
        {props.pad.owner && (
          <div className="ListItemProfile">
            <div className="ListItemProfilePhoto">
              <img alt={props.pad.owner.name} src={process.env.PUBLIC_URL + "/people-default.svg"}/>
            </div>
            <div className="ListItemProfileName">
              {props.pad.owner.name}
            </div>
          </div>
        )}
        <div className="ListItemSubtitle">Owner</div>
      </div>
      <div className="ListCol4">
        <div className="ListItemProfile">
          <div className="ListItemProfilePhoto">
            <img alt="" src={sourceIconUrl} onError={({currentTarget}) => {
              currentTarget.onerror = null;
              currentTarget.src = getUrlIconFallback(props.pad.entity);
            }}/>
          </div>
          <div className="ListItemProfileName">
            {props.pad.source && props.pad.source.charAt(0).toUpperCase() + props.pad.source.substring(1).toLowerCase()}
          </div>
        </div>
        <div className="ListItemSubtitle">Platform</div>
      </div>
      <div className="ListCol5">
        <div>{props.pad.persona}</div>
        <div className="ListItemSubtitlePersona">Persona</div>
      </div>
      <div className="ListCol6">
        <div className="ListSeeReport"><button className="navButton">View report &nbsp;⟩</button></div>
      </div>
    </div>
  )
}

function ListCategory(props) {
  return (
    <div className="ListCategory">
      <div className="ListItem" onClick={() => props.onPanelOpen(props.category)}>
        <div className="ListCategoryTitle">{props.category}</div>
        <div className="ListCategoryButton">
          <button className="categoryButton">
            {props.open ? "﹀" : "⟩"}
          </button>
        </div>
      </div>
      <div className="ListChildren" >
        {props.open && props.pads.map(pad => (
          <ListItem key={pad.id} pad={pad}/>
        ))}
      </div>
    </div>
  )
}

export default function List() {
  const [openPanel, setOpenPanel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const callListPads = async () => {
      const result = await API.graphql(graphqlOperation(listPads));

      const pads = result?.data?.listPads?.items || [];
      const categoryMap = {};
      pads.forEach(pad => {
        if (!categoryMap[pad.category]) {
          categoryMap[pad.category] = [];
        }
        categoryMap[pad.category].push(pad);
      });

      const categories = Object.entries(categoryMap);
      categories.sort(([keyA, padsA], [keyB, padsB]) => {
        const orderA = Math.min(...padsA.map(pad => pad.order || 10000));
        const orderB = Math.min(...padsB.map(pad => pad.order || 10000));
        return orderA - orderB;
      });
      categories.forEach(([key, pads]) => {
        pads.sort((padA, padB) => (padA.order || 0) - (padB.order || 10000));
      });

      if (openPanel === null && categories.length > 0) {
        setOpenPanel(categories[0][0]);
      }

      setCategories(categories);
      setIsLoading(false);
    }
    callListPads();
  }, []);

  const onPanelOpen = (category) => {
    mixpanel.track("Expand category", {category});
    setOpenPanel(category);
  }

  if (isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <div className="ListItems">
      {categories.map(([key, pads]) => (
        <ListCategory key={key} category={key} open={openPanel === key} pads={pads} onPanelOpen={onPanelOpen}/>
      ))}
    </div>
  );
}